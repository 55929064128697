var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("Entries")]),_c('v-select',{staticClass:"per-page-selector d-inline-block ml-50 mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('b-button',{attrs:{"variant":"primary","to":{ name: 'books-credit-note-add' }}},[_vm._v(" Add Record ")])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-select',{staticClass:"invoice-filter-select",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"placeholder":"Select Status"},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(" "+_vm._s(label)+" ")])]}}]),model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1)])],1)],1),_c('b-table',{ref:"refCreditNoteListTable",staticClass:"position-relative",attrs:{"items":_vm.fetchCreditNotes,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"head(creditNoteStatus)",fn:function(){return [_c('feather-icon',{staticClass:"mx-auto",attrs:{"icon":"TrendingUpIcon"}})]},proxy:true},{key:"cell(id)",fn:function(data){return [_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{ name: 'books-credit-note-preview', params: { id: data.item.id } }}},[_vm._v(" #"+_vm._s(data.value)+" ")])]}},{key:"cell(creditNoteStatus)",fn:function(data){return [_c('b-avatar',{attrs:{"id":("credit-note-row-" + (data.item.id)),"size":"32","variant":("light-" + (_vm.resolveCreditNoteStatusVariantAndIcon(data.item.creditNoteStatus)
            .variant))}},[_c('feather-icon',{attrs:{"icon":_vm.resolveCreditNoteStatusVariantAndIcon(data.item.creditNoteStatus).icon}})],1),_c('b-tooltip',{attrs:{"target":("credit-note-row-" + (data.item.id)),"placement":"top"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(data.item.creditNoteStatus)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Balance: "+_vm._s(data.item.balance)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Due Date: "+_vm._s(data.item.dueDate)+" ")])])]}},{key:"cell(client)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":data.item.avatar,"text":_vm.avatarText(data.item.client.name),"variant":("light-" + (_vm.resolveClientAvatarVariant(
              data.item.creditNoteStatus
            )))}})]},proxy:true}],null,true)},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(data.item.client.name)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(data.item.client.companyEmail))])])]}},{key:"cell(issuedDate)",fn:function(data){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(balance)",fn:function(data){return [(data.value === 0)?[_c('b-badge',{attrs:{"pill":"","variant":"light-success"}},[_vm._v(" Paid ")])]:[_vm._v(" "+_vm._s(data.value)+" ")]]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"id":("credit-note-row-" + (data.item.id) + "-send-icon"),"icon":"SendIcon","size":"16"}}),_c('b-tooltip',{staticClass:"cursor-pointer",attrs:{"title":"Send Credit Note","target":("credit-note-row-" + (data.item.id) + "-send-icon")}}),_c('feather-icon',{staticClass:"mx-1",attrs:{"id":("credit-note-row-" + (data.item.id) + "-preview-icon"),"icon":"EyeIcon","size":"16"},on:{"click":function($event){return _vm.$router.push({
              name: 'books-credit-note-preview',
              params: { id: data.item.id },
            })}}}),_c('b-tooltip',{attrs:{"title":"Preview Credit Note","target":("credit-note-row-" + (data.item.id) + "-preview-icon")}}),_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"p-0","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',[_c('feather-icon',{attrs:{"icon":"DownloadIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Download")])],1),_c('b-dropdown-item',{attrs:{"to":{
              name: 'books-credit-note-edit',
              params: { id: data.item.id },
            }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Edit")])],1),_c('b-dropdown-item',[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1),_c('b-dropdown-item',[_c('feather-icon',{attrs:{"icon":"CopyIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Duplicate")])],1)],1)],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"\n          d-flex\n          align-items-center\n          justify-content-center justify-content-sm-start\n        ",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.dataMeta.from)+" to "+_vm._s(_vm.dataMeta.to)+" of "+_vm._s(_vm.dataMeta.of)+" entries")])]),_c('b-col',{staticClass:"\n          d-flex\n          align-items-center\n          justify-content-center justify-content-sm-end\n        ",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalCreditNotes,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }